import {axiosData} from "@/store/api/axios";

const state = () => ({
  temporadas: JSON.parse(localStorage.getItem('temporadas')) || null,
  temporada: JSON.parse(localStorage.getItem('temporada')) || null,

});

const getters = {
  getTemporadas(state) {
    return state.temporadas;
  },
  getTemporada(state) {
    return state.temporada;
  },

};

const actions = {
  setTemporada({ commit }, payload) {
    commit('SET_TEMPORADA', payload);
    payload
        ? localStorage.setItem('temporada', JSON.stringify(payload))
        : localStorage.removeItem('temporada');
  },
  setTemporadas({ commit }, payload) {
    commit('SET_TEMPORADAS', payload);
    payload
        ? localStorage.setItem('temporadas', JSON.stringify(payload))
        : localStorage.removeItem('temporadas');
  },
  async getTemporadasByTag(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Temporadas');

    try {
      const data = await axiosData(
          true,
          'temporadas/negociotag/'+tag,
          'get',
          false,
          false
      );
      console.log(data)
      if(data!== 404){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);
        return null;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async deleteTemporada(_,id){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Borrando Temporada');

    try {
      const data = await axiosData(
          true,
          'temporadas/'+id,
          null,
          'delete',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async saveTemporadas(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Grabando Temporada');

    try {
      const data = await axiosData(
          true,
          'temporadas/editar',
          payload,
          'post',
          false,
          false
      );
      this.dispatch('ui/handleLoaderBtn', false);

      if(data){
        return data;
      }

    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
};

const mutations = {
  SET_TEMPORADAS(state, payload) {
    state.temporadas = payload;
    localStorage.setItem('temporadas', JSON.stringify(payload));
  },
  SET_TEMPORADA(state, payload) {
    state.temporada = payload;
    localStorage.setItem('temporada', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
